// ********* OnlineShop Website is created by Zpunet ******************
// ********* If you get an error please contact us ******
// ******** Email:minahmmassy@gmail.com *********
// ********* Website:www.codemarketi.com *********
// ********* Phone:+255 65 535 2744 *********
// ********* Youtub Channel: https://www.youtube.com/channel/UCOYwYO-LEsrjqBs6xXSfq1w *********

// ******** Support my work with *********
// ********* https://www.patreon.com/zpunet *********
// ********* https://www.buymeacoffee.com/zpunet *********

// ********* This is the main component of the website *********
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { PopUpProvider } from "./Context/PopUpContex";
import ToastContainer from "./components/Notifications/Toaster";
import ScrollToTop from "./ScrollOnTop";
import BigLoader from "./components/Notifications/BigLoader";
import { ProtectedRouter } from "./ProtectedRouter";

const HomeScreen = React.lazy(() => import("./screens/HomeScreen"));
const AboutUs = React.lazy(() => import("./screens/AboutUs"));
const ContactUs = React.lazy(() => import("./screens/ContactUs"));
const Shop = React.lazy(() => import("./screens/Shop"));
const FAQPage = React.lazy(() => import("./screens/FAQ"));
const Blog = React.lazy(() => import("./screens/Blog"));
const Post = React.lazy(() => import("./screens/Post"));
const PrivacyPolicy = React.lazy(() => import("./screens/Privacy"));
const TermAndConditions = React.lazy(() => import("./screens/TermsCondition"));
const OrderScreen = React.lazy(() => import("./screens/OrderScreen"));
const Dashboard = React.lazy(() => import("./screens/Dashboard/Dashboard"));
const Orders = React.lazy(() => import("./screens/Dashboard/Orders"));
const UpdateProfile = React.lazy(() =>
  import("./screens/Dashboard/UpdateProfile")
);
const Password = React.lazy(() => import("./screens/Dashboard/Password"));
const RegistationScreen = React.lazy(() =>
  import("./screens/Dashboard/RegistationScreen")
);
const NotFound = React.lazy(() => import("./screens/NotFound"));
const ProductDetails = React.lazy(() => import("./screens/ProductDetails"));

function App() {
  AOS.init();
  return (
    <PopUpProvider>
      <ToastContainer />
      <ScrollToTop>
        <Routes>
          {/* PROTECTED */}
          <Route element={<ProtectedRouter />}>
            <Route
              path="/order/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <OrderScreen />
                </Suspense>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/orders"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Orders />
                </Suspense>
              }
            />
            <Route
              path="/profile"
              element={
                <Suspense fallback={<BigLoader />}>
                  <UpdateProfile />
                </Suspense>
              }
            />
            <Route
              path="/password"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Password />
                </Suspense>
              }
            />
          </Route>

          {/* PUBLIC */}
          <Route
            path="/"
            element={
              <Suspense fallback={<BigLoader />}>
                <HomeScreen />
              </Suspense>
            }
          />
          <Route
            path="/card/:id"
            element={
              <Suspense fallback={<BigLoader />}>
                <ProductDetails />
              </Suspense>
            }
          />
          <Route
            path="/registation"
            element={
              <Suspense fallback={<BigLoader />}>
                <RegistationScreen />
              </Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <Suspense fallback={<BigLoader />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Suspense fallback={<BigLoader />}>
                <ContactUs />
              </Suspense>
            }
          />
          <Route
            path="/shop"
            element={
              <Suspense fallback={<BigLoader />}>
                <Shop />
              </Suspense>
            }
          />
          <Route
            path="/faq"
            element={
              <Suspense fallback={<BigLoader />}>
                <FAQPage />
              </Suspense>
            }
          />
          <Route
            path="/blog"
            element={
              <Suspense fallback={<BigLoader />}>
                <Blog />
              </Suspense>
            }
          />
          <Route
            path="/blog/post"
            element={
              <Suspense fallback={<BigLoader />}>
                <Post />
              </Suspense>
            }
          />
          <Route
            path="/terms-condition"
            element={
              <Suspense fallback={<BigLoader />}>
                <TermAndConditions />
              </Suspense>
            }
          />
          <Route
            path="/policy"
            element={
              <Suspense fallback={<BigLoader />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<BigLoader />}>
                <NotFound />
              </Suspense>
            }
          />
        </Routes>
      </ScrollToTop>
    </PopUpProvider>
  );
}

export default App;
